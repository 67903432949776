<template>
  <div class="keranjang">
    <NavbarCom :updateKeranjang="keranjangs"></NavbarCom>
    <div class="container">
      <!-- breadcrumb-->
      <div class="row mt-5">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" class="text-dark">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/foods" class="text-dark">Food</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Keranjang
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end breadcrumb-->

      <div class="row">
        <div class="col">
          <h2>Keranjang <strong>Saya</strong></h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Gambar</th>
                <th scope="col">Pesanan</th>
                <th scope="col">Harga</th>
                <th scope="col">Jumlah Pesan</th>
                <th scope="col">Total Harga</th>
                <th scope="col">Keterangan</th>
                <th scope="col">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(keranjang, index) in keranjangs" :key="keranjang.id">
                <th>{{ index + 1 }}</th>
                <td>
                  <img
                    :src="'../assets/images/' + keranjang.gambar"
                    class="img-fluid shadow"
                    width="100"
                  />
                </td>
                <td>{{ keranjang.nama }}</td>
                <td align="right">Rp. {{ keranjang.harga }}</td>
                <td align="right">{{ keranjang.jumlah_pemesanan }}</td>
                <td align="right">
                  <strong
                    >Rp.
                    {{ keranjang.harga * keranjang.jumlah_pemesanan }}</strong
                  >
                </td>
                <td>{{ keranjang.pesan }}</td>
                <td align="center" class="text-danger">
                  <b-icon-trash
                    @click="hapusKeranjang(keranjang.id)"
                  ></b-icon-trash>
                </td>
              </tr>
              <tr>
                <td colspan="5" align="right">
                  <strong>Total harga </strong>
                </td>
                <td align="right">
                  <strong>Rp. {{ totalHarga }}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--form cek out-->
      <div class="row justify-content-end">
        <div class="col-md-4">
          <form class="mt-4" v-on:submit.prevent>
            <div class="form-group">
              <label for="nama">Nama </label>
              <input
                type="text"
                class="form-control"
                v-model="pesan.nama"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="noMeja">Nomer Meja </label>
              <input
                type="text"
                class="form-control"
                v-model="pesan.noMeja"
                disabled
              />
            </div>
            <button
              type="submit"
              class="btn btn-success float-right"
              @click="checkout1"
            >
              <b-icon-cart></b-icon-cart> Pesan
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavbarCom from "@/components/NavbarCom.vue";
import axios from "axios";
export default {
  name: "KeranjangView",
  components: {
    NavbarCom,
  },
  data() {
    return {
      keranjangs: {},
      pesan: {},
    };
  },
  methods: {
    setKeranjang(data) {
      this.keranjangs = data;
    },
    hapusKeranjang(id) {
      axios
        .delete("https://api.idekitakreatif.my.id/api/keranjangs/" + id)
        .then(() => {
          this.$toast.error("Sukses hapus keranjang", {
            type: "success",
            position: "top-right",
            duration: 3000,
            dismissible: true,
          });
          axios
            .get(
              "https://api.idekitakreatif.my.id/api/keranjangs/displayKeranjang"
            )
            .then((response) => this.setKeranjang(response.data.data));
        })
        .catch((error) => console.log("gagal :", error));

      //reload lagi
      //axios
      //  .get("http://localhost:8000/api/keranjangs/displayKeranjang")
      //  .then((response) => this.setKeranjang(response.data.data))
      //  .catch((error) => console.log("gagal :", error));
    },
    checkout1() {
      if (this.pesan.nama && this.pesan.noMeja) {
        //this.pesan.keranjangs = this.keranjangs;
        this.pesan.id_keranjang = this.keranjangs.id;
        this.pesan.status = 0;
        axios
          .post("https://api.idekitakreatif.my.id/api/pesanans", this.pesan)
          .then(() => {
            this.keranjangs.map(function (item) {
              return axios
                .delete(
                  "https://api.idekitakreatif.my.id/api/keranjangs/" + item.id
                )

                .catch((error) => console.log("gagal :", error));
            });
            this.$router.push({ path: "/pesanan-sukses" });
            this.$toast.success("Sukses dipesan", {
              type: "success",
              position: "top-right",
              duration: 3000,
              dismissible: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$toast.error("isi nama dan nomer meja", {
          type: "error",
          position: "top-right",
          duration: 3000,
          dismissible: true,
        });
      }
    },
  },
  mounted() {
    this.pesan.nama = localStorage.getItem("nama");
    this.pesan.noMeja = localStorage.getItem("meja");
    axios
      .get("https://api.idekitakreatif.my.id/api/keranjangs/displayKeranjang")
      .then((response) => this.setKeranjang(response.data.data))

      .catch((error) => console.log("gagal :", error));
  },
  computed: {
    totalHarga() {
      return this.keranjangs.reduce(
        (items, data) => items + data.harga * data.jumlah_pemesanan,
        0
      );
    },
  },
};
</script>

<style></style>
