<template>
  <div class="hero">
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="d-flex h-100">
          <div class="justify-content-center align-self-center">
            <h2><strong>Makanan Datang</strong> <br />dengan sekali klik</h2>
            <p>Pilih dan pesan menu suka suka</p>
            <router-link class="btn btn-lg btn-success" to="/foods">
              <b-icon-arrow-right></b-icon-arrow-right>
              Pesan
            </router-link>
          </div>
        </div>
      </div>
      <div class="col">
        <img src="../assets/images/hero.png" width="100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroCom",
};
</script>

<style></style>
