<template>
  <div class="food-detail">
    <NavbarCom></NavbarCom>
    <div class="container">
      <!-- breadcrumb-->
      <div class="row mt-5">
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" class="text-dark">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/foods" class="text-dark">Food</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Pesanan
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end breadcrumb-->
      <div class="row mt-3">
        <div class="col-md-6">
          <img
            :src="'../assets/images/' + product.gambar"
            class="img-fluid shadow"
          />
        </div>
        <div class="col-md-6">
          <h2>
            <strong>{{ product.nama }}</strong>
          </h2>
          <hr />
          <h4>
            harga :
            <strong>Rp. {{ product.harga }}</strong>
          </h4>
          <form class="mt-4" v-on:submit.prevent>
            <div class="form-group">
              <label for="jumlah_pesan">Jumlah Pesan</label>
              <input
                type="number"
                class="form-control"
                v-model="pesan.jumlah_pemesanan"
              />
            </div>
            <div class="form-group">
              <label for="keterangan">Keterangan</label>
              <textarea
                class="form-control"
                v-model="pesan.pesan"
                placeholder="pedas, nasi setengah"
              ></textarea>
            </div>
            <button type="submit" class="btn btn-success" @click="pemesanan">
              <b-icon-cart></b-icon-cart> Pesan
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarCom from "@/components/NavbarCom.vue";
import axios from "axios";
export default {
  name: "FoodsDetail",
  components: {
    NavbarCom,
  },
  data() {
    return {
      product: {},
      pesan: {},
    };
  },
  methods: {
    setProduct(data) {
      this.product = data;
    },
    pemesanan() {
      if (this.pesan.jumlah_pemesanan) {
        //this.pesan.product = this.product;
        this.pesan.kode_barang = this.product.kode;
        this.pesan.harga = this.product.harga;
        this.pesan.no_meja = localStorage.getItem("no_meja");
        console.log(this.pesan);
        axios
          .post("https://api.idekitakreatif.my.id/api/keranjangs/", this.pesan)
          .then(() => {
            this.$router.push({ path: "/foods" });
            this.$toast.success("Pesanan sukses, cek keranjang", {
              type: "success",
              position: "top-right",
              duration: 3000,
              dismissible: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$toast.error("Isi jumlah pesanan", {
          type: "error",
          position: "top-right",
          duration: 3000,
          dismissible: true,
        });
      }
    },
  },
  mounted() {
    axios
      .get(
        "https://api.idekitakreatif.my.id/api/posts/" + this.$route.params.id
      )
      .then((response) => this.setProduct(response.data.data))

      .catch((error) => console.log("gagal :", error));
  },
};
</script>

<style></style>
