<template>
  <footer class="mt-5 mb-5">
    <hr />
    <div class="container">
      <div class="row">
        <div class="col text-center">2024 Copyright Coba Test</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterCom",
};
</script>

<style></style>
