<template>
  <div>
    <NavbarCom></NavbarCom>
    <div class="container">
      <div class="row mt-4">
        <div class="col">
          <h2>Daftar <strong>Makanan</strong></h2>
        </div>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 text-start"></div>
        <div class="col-lg-8 text-end">
          <div class="ph3">
            <a
              class="f6 link dim br-pill ph3 pv2 mb2 dib white bg-dark-green"
              href="#0"
              >Semua</a
            >

            <a
              class="f6 link dim br-pill ph3 pv2 mb2 dib white bg-dark-green"
              href="#0"
              >Makanan</a
            >
            <a
              class="f6 link dim br-pill ph3 pv2 mb2 dib white bg-dark-green"
              href="#0"
              >Minuman</a
            >
            <a
              class="f6 link dim br-pill ph3 pv2 mb2 dib white bg-dark-green"
              href="#0"
              >Snack</a
            >
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <div class="input-group mb-3">
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="Cari Makanan Kesukaan Anda"
              aria-label="Cari"
              aria-describedby="basic-addon2"
              @keyup="searchFood"
            />
            <button type="submit" class="btn btn-primary" @click="searchFood">
              <b-icon-search></b-icon-search> Cari
            </button>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div
          class="col-md-4 mt-4"
          v-for="product in products"
          :key="product.id"
        >
          <CardProduct :product="product"></CardProduct>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarCom from "@/components/NavbarCom.vue";
import CardProduct from "@/components/CardProduct.vue";
import axios from "axios";
export default {
  name: "FoodsView",
  components: {
    NavbarCom,
    CardProduct,
  },
  data() {
    return {
      products: [],
      search: "",
    };
  },
  methods: {
    setProduct(data) {
      this.products = data;
    },
    searchFood() {
      axios
        .get(
          "https://api.idekitakreatif.my.id/api/posts/search?search=" +
            this.search
        )
        //.get("https://api.idekitakreatif.my.id/api/posts")
        .then((response) => this.setProduct(response.data.data))

        .catch((error) => console.log("gagal :", error));
    },
  },
  mounted() {
    // Make a request for a user with a given ID
    axios
      .get("https://api.idekitakreatif.my.id/api/posts/GetAll")
      .then((response) => this.setProduct(response.data.data))

      .catch((error) => console.log("gagal :", error));
  },
};
</script>

<style></style>
