<template>
  <div id="app">
    <div class="login-page">
      <transition name="fade">
        <div class="wallpaper-login"></div>
      </transition>
      <div class="wallpaper-register"></div>

      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
            <div class="card login">
              <h1>Login</h1>
              <form class="form-group">
                <input
                  v-model="mejaLogin"
                  type="text"
                  class="form-control"
                  placeholder="Nomor Meja"
                  required
                />
                <input
                  v-model="namaLogin"
                  type="text"
                  class="form-control"
                  placeholder="Nama"
                  required
                />
                <input type="submit" class="btn btn-primary" @click="doLogin" />
              </form>
            </div>

            <div class="card register"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
h1 {
  text-align: center;
}
</style>
<script>
export default {
  // mounted() {
  //   if (localStorage.nama) {
  //     this.nama = localStorage.nama;
  //   }
  //   if (localStorage.umur) {
  //     this.umur = localStorage.umur;
  //   }
  // },
  methods: {
    setNama() {
      localStorage.setItem("nama", this.namaLogin);
    },
    setMeja() {
      localStorage.setItem("meja", this.mejaLogin);
    },
    //getVal() {
    //  this.name = localStorage.getItem("name");
    //},
    //removeVal() {
    //  localStorage.removeItem("name");
    //  this.name = "";
    //},
    doLogin() {
      if (this.mejaLogin && this.namaLogin) {
        this.setMeja();
        this.setNama();
        this.$router.push({ path: "/home" });
      } else {
        this.$toast.error("Isi nomor meja dan nama", {
          type: "error",
          position: "top-right",
          duration: 3000,
          dismissible: true,
        });
      }
    },
  },
};
</script>
