<template>
  <div class="home">
    <NavbarCom></NavbarCom>
    <div class="container">
      <HeroCom></HeroCom>
      <div class="row mt-4">
        <div class="col">
          <h2>Menu <strong>Terlaris</strong></h2>
        </div>
        <div class="col">
          <router-link to="/foods" class="btn btn-success float-right">
            <b-icon-eye></b-icon-eye> Lihat Semua</router-link
          >
        </div>
      </div>

      <div class="row mb-4">
        <div
          class="col-md-4 mt-4"
          v-for="product in products"
          :key="product.id"
        >
          <CardProduct :product="product"></CardProduct>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarCom from "@/components/NavbarCom.vue";
import HeroCom from "@/components/HeroCom.vue";
import CardProduct from "@/components/CardProduct.vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    NavbarCom,
    HeroCom,
    CardProduct,
  },
  data() {
    return {
      products: [],
    };
  },
  methods: {
    setProduct(data) {
      this.products = data;
    },
  },
  mounted() {
    // Make a request for a user with a given ID
    axios
      .get("https://api.idekitakreatif.my.id/api/posts")
      .then((response) => {
        //console.log(response.data);
        this.setProduct(response.data.data);
      })
      .catch((error) => console.log("gagal :", error));
  },
};
</script>
