<template>
  <div class="pesanan-sukses">
    <NavbarCom :updateKeranjang="keranjangs"></NavbarCom>
    <div class="container">
      <h2>Pesanan sukses</h2>
    </div>
  </div>
</template>

<script>
import NavbarCom from "@/components/NavbarCom.vue";
import axios from "axios";
export default {
  name: "pesananSukses",
  components: {
    NavbarCom,
  },
  data() {
    return {
      keranjangs: {},
    };
  },
  methods: {
    setKeranjang(data) {
      this.keranjangs = data;
    },
  },
  mounted() {
    axios
      .get("https://api.idekitakreatif.my.id/keranjangs/")
      .then((response) => this.setKeranjang(response.data))

      .catch((error) => console.log("gagal :", error));
  },
};
</script>

<style></style>
