<template>
  <div class="container">
    <b-navbar toggleable="lg" type="light" variant="transparent">
      <b-navbar-brand href="#"
        ><router-link class="navbar-brand" to="/home"
          >Kulineran</router-link
        ></b-navbar-brand
      >

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/home">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/Foods"
                >Daftar Menu</router-link
              >
            </li>
          </ul>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/keranjang">
              keranjang
              <b-icon-cart></b-icon-cart>
              <span class="badge badge-success ml-2">{{
                updateKeranjang ? updateKeranjang.length : jumlah_pesanan.length
              }}</span>
            </router-link>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NavbarCom",
  data() {
    return {
      jumlah_pesanan: [],
    };
  },
  props: ["updateKeranjang"],
  methods: {
    setJumlah(data) {
      this.jumlah_pesanan = data;
    },
  },
  mounted() {
    axios
      .get("https://api.idekitakreatif.my.id/api/keranjangs/")
      .then((response) => this.setJumlah(response.data.data))

      .catch((error) => console.log("gagal :", error));
  },
};
</script>

<style></style>
