import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import FoodsView from "../views/FoodsView.vue";
import KeranjangView from "../views/KerangjangView.vue";
import DetailFoodsView from "../views/DetailFoodsView.vue";
import pesananSukses from "../views/pesananSukses.vue";
import LoginView from "../views/LoginView.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/foods",
    name: "foods",
    component: FoodsView,
  },
  {
    path: "/foods/:id",
    name: "FoodDetail",
    component: DetailFoodsView,
  },
  {
    path: "/Keranjang",
    name: "Keranjang",
    component: KeranjangView,
  },
  {
    path: "/pesanan-sukses",
    name: "pesananSukses",
    component: pesananSukses,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
